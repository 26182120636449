/* eslint-disable @next/next/no-img-element */
import Script from "next/script";

import { ENVIRONMENT, PRODUCTION } from "@shared/constants";

export default function TrustedForm() {
  return (
    <>
      <Script
        type="text/javascript"
        src={`https://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=${new Date().getTime() + Math.random()}${ENVIRONMENT === PRODUCTION ? "" : "&sandbox=true"}`}
        id="trusted-form"
        async={true}
      />
      <noscript>
        <img
          src="https://api.trustedform.com/ns.gif"
          className="absolute -z-50"
          alt=""
        />
      </noscript>
    </>
  );
}
