"use client";

import { useFormikContext } from "formik";
import Link from "next/link";

import { SITE_NAME } from "@shared/constants";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import TrustedForm from "@client/components/trusted-form";

type Props = {
  baseUrl?: string;
};

export default function Email({ baseUrl = "" }: Props) {
  const { values } = useFormikContext<any>();

  const btnText = values.submitMethod === "submit" ? "Submit" : "Continue";

  return (
    <>
      <Input type="hidden" name="agreesToDataSharing" />
      <Input type="hidden" name="agreesToSoftPull" />
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Fieldset
        dialog="We request your email to provide essential updates, timely communication, and access to your personalized debt solutions. We're committed to not spamming and keeping your email information protected."
        legend="What is your email?"
      >
        <Field label="Email" name="email">
          <Input type="email" name="email" />
        </Field>
      </Fieldset>
      <Button />
      <div className="form__consents" data-spec-lead-form-consents>
        <p>
          By clicking <b className="uppercase">{btnText}</b> above, you
          understand and agree to the following:
        </p>
        <p>
          You agree to {SITE_NAME}'s{" "}
          <Link href={`${baseUrl}/terms-of-service`} target="_blank">
            Terms of Service
          </Link>
          ,{" "}
          <Link href={`${baseUrl}/privacy-policy`} target="_blank">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link href={`${baseUrl}/esign-consent`} target="_blank">
            E-Sign Consent
          </Link>
          .
        </p>
        <p>
          You agree to our{" "}
          <Link
            href={`${baseUrl}/credit-authorization-agreement`}
            target="_blank"
          >
            Credit Authorization Agreement
          </Link>{" "}
          and authorize {SITE_NAME} and its{" "}
          <Link href={`${baseUrl}/providers`} target="_blank">
            Providers
          </Link>
          , under the Fair Credit Reporting Act (FCRA) to obtain your consumer
          report, credit profile, or other credit information associated with
          you from any consumer reporting agency in order to verify your
          identity and assess your eligibility for financial services and products.
        </p>
      </div>
      <TrustedForm />
    </>
  );
}
