"use client";

import { FieldAttributes, useField, useFormikContext } from "formik";

import Field from "@ui/js/components/form/field";

export default function LeadFormField(props: FieldAttributes<any>) {
  const [_, meta] = useField(props);
  const { status } = useFormikContext();

  const error =
    meta.error ||
    (status === "error" &&
      "An error occurred while processing your request. Please try again.");

  const state = (meta.error || status === "error") && "error";

  return <Field {...props} message={error} state={state} />;
}
